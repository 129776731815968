.container{
  padding:.5rem;
}
.video-container{
  border-radius: 4px;
  border: solid 1px rgba(0,0,0,.04);
  text-align: center;
  padding: 1rem;
}
.video-container iframe{
  border-radius: 4px;
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
}
.video-container blockquote,.tiktok-embed iframe{
  margin: 0 auto;
  padding: 0;
  width: 100%;
  max-width: 100%;
}
.post-video-area{
  margin-top: .5rem;
  padding:1rem;
  border-top: solid 1px rgba(0,0,0,.04);
}
.share-menu button{
  padding:.2rem !important;
}
.share-menu svg{
  width: 2rem;
  height: 2rem;
  border-radius: 4px;
}
#share-menu{
  padding:.5rem;
}
.enter-modal{
  min-width: 50%;
  background-color: #fff;
  border-radius: 4px;
  padding: 1rem;
  margin: 0 auto;
  margin-top: 1.5rem;
  max-width: 95%;
  text-align: center;
}
.entry-instructions{
  padding:1rem;
  font-size: .8rem;;
}
.mb-2{
  margin-bottom: 2rem !important;
}
.w-100{
 width: 100%;
}
.tt-video {
  width: 100%;
  aspect-ratio: 7 / 16; /* or whatever the aspect ratio of your video is */
  overflow: hidden;
  border:none;
}
.yt-video{
  width: 100%;
  aspect-ratio: 16 / 9; /* or whatever the aspect ratio of your video is */
  overflow: hidden;
  border:none;
}
.ig-video{
  width: 100%;
  aspect-ratio: 16 / 9; /* or whatever the aspect ratio of your video is */
  overflow: hidden;
  border:none;
}
.share-tt-video,.share-yt-video,.share-ig-video {
  width: 100%;
  max-height:100%;
  overflow: hidden;
  border:none;
}

.vote-modal{
  background-color: #fff;
  border-radius: 4px;
  padding: 1rem;
  margin: 0 auto;
  margin-top: 1.5rem;
  max-width: 95%;
  text-align: center;
}
.shared-modal{
  min-width: 80%;
  background-color: #fff;
  border-radius: 4px;
  padding: 1rem;
  margin: 0 auto;
  margin-top: 1.5rem;
  max-width: 90%;
  max-height: 90%;
}
.shared-modal div{
  border: none, i !important;
}
.top-nav-button{
  color:#fff !important;
}
.share-buttons-in-modal{
  padding:1rem 0;
}
.share-buttons-in-modal button svg{
  width: 40px;
  height: 40px;
  border-radius: 4px;
}
.share-buttons-in-modal button{
  padding:.2rem !important;
}
.video-preview-entry iframe{
  width: 100%;
  height: 100%;
  border-radius: 4px;
  min-height: 400px;
}
.text-center{
  text-align: center;
}
.text-left{
  text-align: left;
}
.text-right{
  text-align: right;
}

/* mobile layout */
@media only screen and (max-width: 600px) {
  
 
  .video-preview-entry iframe{
    min-height: 200px;
  }
  .video-container {
   padding: 1rem;
}
.container{
  padding:.2rem;
}
}